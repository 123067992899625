import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"


const SahkoinenIlmoittautuminenKoulutuspaiville = ({
  location: { pathname },
}) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Sähköinen ilmoittautuminen koulutuspäiville" />

    {/* <div className="map-wraper full-container">
      <div className="map-wraper-in">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2249.7250302345374!2d25.729457226714086!3d66.50219984194453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x442b4bf3127a7119%3A0xe63e167ef6df6e79!2sKorkalonkatu%2029%2C%2096200%20Rovaniemi!5e0!3m2!1sen!2sfi!4v1694672936670!5m2!1sen!2sfi"
          width="100%"
          height="300"
          frameborder="0"
          allowFullScreen
          title="google-map"
        />
      </div>
    </div> */}
    <div id="content" className="content full pt-10 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-md-offset-2">
            <h1>Seuraavat koulutuspäivät Porvoossa 18.-19.3.2025</h1>
            <h3>Ilmoittautuminen avataan myöhemmin.</h3>
          </div>
        </div>
      </div>
    </div>

    <div className="spacer-60" />
  </Layout>
)

export default SahkoinenIlmoittautuminenKoulutuspaiville
