import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { YoutubePlayer } from "reactjs-media"

const AiemmatLuentomateriaalit = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Aiemmat luentomateriaalit" />

    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Aiemmat luentomateriaalit</h1>
        </div>
      </div>
    </div>
    <div id="content" className="content full">
      <div className="container">
        <div className="spacer-60" />
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
          <h2>Koulutuspäivät Kokkolassa 5.-6.11.2024</h2>
          <p>
            <a href="https://www.canva.com/design/DAGVavtpy_8/Ht9ErXewtKKo_kJ5BwisDg/watch?utm_content=DAGVavtpy_8&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel" target="_blank">Oy Codesto Ab - Virtuaaliteknologia omaisten ja hautausmaiden apuna</a>
          </p>
          <p>
              <a href="/doc/koulutuspaivat/kokkola_2024/vitec_katrina_hautarekisteri.pdf">Vitec - Katrina hautarekisteri</a>
          </p>
          <p>
            <a href="/doc/koulutuspaivat/kokkola_2024/turkoosi_oy_krematorian_suunnitteluhaasteet_ville_varjo.pptx">Turkoosi Oy - Krematorian suunnitteluhaasteet, Ville Varjo</a>
          </p>
          <p>
            <a href="/doc/koulutuspaivat/kokkola_2024/kokkolan_seurakuntayhtyma_alueskeskusrekisterit_heli_vepsalainen.pptx">Kokkolan seurakuntayhtyma - Alueskeskusrekisterit, Heli Vepsäläinen</a>
          </p>
          <p>
            <a href="/doc/koulutuspaivat/kokkola_2024/kirkkohallitus_hautaustoimen_ajankohtaisia_asioita_harri_palo.pdf">Kirkkohallitus - Hautaustoimen ajankohtaisia asioita, Harri Palo</a>
          </p>
          <p>
            <a href="/doc/koulutuspaivat/kokkola_2024/kivilahde_muistomerkit_seurakunnille.pdf">Kivilahde - Muistomerkit seurakunnille</a>
          </p>
          <p>
            <a href="/doc/koulutuspaivat/kokkola_2024/kokkolan_seurakuntayhtyma_valmiussuunnittelu.pptx">Kokkolan seurakuntayhtyma - Valmiussuunnittelu ja varautuminen Kokkolan seurakuntayhtymässä</a>
          </p>
         
            <h2>Koulutuspäivät Espoossa 19.-20.3.2024</h2>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/hautauspalvelut_espoon_seurakuntayhtyma_ollila.pdf">
                Juha Ollila, Espoon seurakuntayhtymä: Hautauspalvelut
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/hautaustoimen_ajankohtaiset_asiat_palo.pdf">
                Harri Palo, maankäyttöpäällikkö, Kirkkohallitus: Hautaustoimen ajankohtaiset asiat
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/kellonummen_niittyhautausmaa_lopponen.pdf">
                Viia Löppönen, Kellonummen niittyhautausmaa
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/kirkko_kuolema_bjorklund_tuohimaki.pdf">
                Liisa Björklund & Juha Tuohimäki: Kirkko ja kuolema - yhteiskehittämisellä parempaa laatua palveluprosessiin
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/kuolindoula_brummer-rauma.pdf">
                Anne Rauma / Luonnollisesti osuuskunta & Anna Brummer, Kuolindoula - Kuoleman äärellä
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/luonnon_ja_hautausmaiden_merkitykset_seurakuntalaisille_sarlin.pdf">
                Urpu Sarlin, Espoon seurakuntayhtymä: Luonnon ja hautausmaiden merkitykset seurakuntalaisille
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/muutos_hautaamisen_alalla_marja_kuronen.pdf">
                Marja Kuronen, Lapin yliopisto: Muutos hautaamisen alalla
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/siviiilihautajaisten_kehitysnakymista_ylikoski.pdf">
                Esa Ylikoski, FM, Vapaa Ajattelijan päätoimittaja: Siviilihautajaisten kehitysnäkymistä
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/tartuntojen_torjunta_vainajatyossa_marttinen.pdf">
                Tutta Marttinen, Hygieniahoitaja: Tartuntojen torjunta vainajatyössä
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/espoo_2024/varautuminen_hautausalalla_kivimaa.pdf">
                Pekka Kivimaa, SHK-tukku: Varautuminen hautausalalla
              </a>
            </p>
            <h2>Koulutuspäivät Rovaniemellä 7.-8.11.2023</h2>
            <p>
              <a href="/doc/koulutuspaivat/rovaniemi_2023/electrical_cremator.ppsx">
                Electrical Cremator
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/rovaniemi_2023/hautausmaan_hoitosuunnitelman_laadintaops.pdf">
                Seurakuntapuutarhurit Ry, Hautausmaan hoitosuunnitelman laadintaopas
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/palo_harri_hyvinkaan_luennot.pptx">
                Harri Palo, maankäyttöpäällikö, Kirkkohallitus: Seurakunnat krematorioiden ylläpitäjinä
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/rovaniemi_2023/jokelan_hautaustoimisto-hautausalan_eurooppalaiset_eettiset_ohjeet.pdf">
                Jokelan Hautaustoimisto, Hautaustoimistojen eettiset ohjeet ja Suomen Hautaustoimistojen Liitto ry:n kauppatapaohjeet
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/rovaniemi_2023/somesta_hautausalan_ammattilaisille.pdf">
                Rovaniemen seurakunta, Sosiaalinen media työkaluna viestinnässä
              </a>
            </p>

            <h2>Koulutuspäivät Hyvinkäällä 28.3.-29.3.2023</h2>
            <p><a href="https://app.slidebean.com/p/qo3vz0akzh/Ekologinen-vesituhkaus" target="_blank">
              Stephanie Sinclair Lappi: Ekologinen vesituhkaus</a></p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/husso_markkum_hyvinkaan_srk_sahkoiset_palvelut.pptx">
                Husso Markku: Hyvinkään seurakunnan sähköiset palvelut
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/kuronen_marja_lapin_yliopisto_hautaamiseen_liittyvien palveluiden_kehitystarpeet.pdf">
                Marja Kuronen, Lapin yliopisto: Hautaamiseen liittyvien palveluiden kehitystarpeet
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/mika_uusi-pietila_gem_matthews.pdf">
                Mika Uusi-Pietilä, Gem Matthews: Krematorion hankintaprosessi
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/murole_miia_hyvinkaan_srk.pptx">
                Miia Murole, Hyvinkään seurakunta: Hyvinkään seurakunnan tervehdys ja esittely
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/palo_harri_hyvinkaan_luennot.pptx">
                Harri Palo, maankäyttöpäällikö, Kirkkohallitus: Hautastoimen kysymyksiä
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/pekka_kivimaa_sht-tukku_oy_esitys_hyvinkaa.pdf">
                Pekka Kivimaa, SHK-tukku: Mikä on merkityksellistä, mutta näkymätöntä?
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/hyvinkaa_2023/rehn_pekka_ylijohtaja_digi-ja_vaestovirasto_kuolleen_laheisen_asioiden_helpottaminen.pptx">
                Pekka Rehn, Ylijohtaja, Digi- ja väestötietovirasto: Kuolleen läheisen asioiden helpottaminen
              </a>
            </p>
            <h2>Koulutuspäivät Vaasassa 8.-9.11.2022</h2>
            <p>
              <a href="/doc/koulutuspaivat/vaasa_2022/8_11_2022_RL_Vaasan_esitelma.pdf">
                Raigo Liiman: Ukrainalaiset seurakunnassa ja Ukrainan info
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/vaasa_2022/Huoltovarmuuskysymykset_seurakunnissa_Ruutiainen_8.11.2022_Vaasa.pdf">
                Taru Ruutiainen, Aluehallintovirasto: Huoltovarmuuskysymykset
                seurakunnissa
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/vaasa_2022/Jatelainsaadanto_ja_hautaustoimi_9.11.22.pptx">
                Esa Hirvijärvi, Vaasan kaupunki, ympäristötoimi: Hautaustoimi ja
                jätehuolto
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/vaasa_2022/Seurakunnan_valmiussuunnittelu_SHTKL_Vaasa_9.11.2022.pdf">
                Jukka Helin, Lapuan hiippakunta: Seurakunnan valmiussuunnittelu
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/vaasa_2022/hautaustoimen_saadokset_ja_niiden_soveltaminen_petri_solja.pptx">
                Petri Solja, Esitys: Hautaustoimen säädökset ja niiden
                soveltaminen
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/vaasa_2022/opinnaytetyo_petri_solja.pdf">
                Petri Solja, Opinnäytetyö: Hautaustoimen säädökset ja niiden
                soveltaminen
              </a>
            </p>
            <h2>Koulutuspäivät Kouvolassa 30.-31.3.2021</h2>
            <p>
              <a href="/doc/koulutuspaivat/kouvola_2022/2022-03-30_SHK_Kouvola_Palo.pdf">
                Maankäyttöpäällikkö Harri Palo, kirkkohallitus: Hautaustoimen
                ajankohtaisia asioita
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/kouvola_2022/hautaustoiminnan-keskusliitto-valmennus31032022-koonti.pdf">
                Aarne Kiviniemi, Hyvinvointivalmennus Hälsä Oy: Itsetuntemus,
                ihmisläheinen organisaatiokulttuuri
              </a>
            </p>
            <p>
              <a href="/doc/koulutuspaivat/kouvola_2022/keva_esitys_pekkarinen_300322.pdf">
                Laura Pekkarinen, KEVA: Seurakuntien työhyvinvoinnin tilannekuva
              </a>
            </p>
            <h2>Koulutuspäivät Turussa 9.-10.11.2021</h2>
            <p>
              <a href="/doc/tuhkauslaitteistot_2021.pdf">
                Höganäs Borgestad: Krematoriolaitteet
              </a>
            </p>
            <p>
              <a href="/doc/koronaepiedemia-ja-seurakuntatoiminta.pdf">
                Anssi Nurmi, hiippakuntasihteeri, turun arkkihiippakunta:
                Koronapandemia ja seurakuntatoiminta
              </a>
            </p>
            <p>
              <a href="/doc/2021-11-10_Absentus_Holvihauta-esitys_TURKU.pdf">
                Absentus: Holvihauta | Uusi vaihtoehto hautaamiseen
              </a>
            </p>
            <p>
              <a href="/doc/2021-11-09_SHK_Turku_Palo.pdf">
                Maankäyttöpäällikkö Harri Palo, kirkkohallitus: Hautaustoimen
                ajankohtaisia asioita
              </a>
            </p>
            <p>
              <a
                href="/doc/Organisaatiorakenne_kaavio_01092019.png"
                target="_blank"
              >
                Turun ja Kaarinan seurakuntayhtymän organisaatiorakenne
              </a>
            </p>
            <p>
              <a href="/doc/Turun_AKR_SHK_Lehto.pdf">
                Suomen hautaustoiminnan keskusliitto: Alueellinen
                keskusrekisteri ja KIRJURI jäsentietojärjestelmä
              </a>
            </p>
            <p>
              <a href="/doc/Katrinan_palvelut_hautaustoimelle-9-11-2021.pdf">
                Aarne Kiviniemi, Vitec: Katrinan palvelut seurakunnan
                asiantuntijan tukena
              </a>
            </p>
            <p>
              <a href="/doc/Hautausmaan_ hoitosuunnitelman laadintaopas.pdf">
                Juha Ollila, Seurakuntapuutarhurit ry: Hautausmaan
                hoitosuunnitelman laadintaopas, sisällysluettelo
              </a>
            </p>
            <p>
              <a href="/doc/P_Sorri_9.11.pdf">
                Pekka Sorri, Turun ja Kaarinan seurakuntayhtymä: Turun ja
                Kaarinan seurakuntayhtymän hautaustoimipalvelut
              </a>
            </p>
            <h2>Järvenpään webinaari 10.3.2021</h2>
            <YoutubePlayer
              src="https://www.youtube.com/watch?v=jTd150PKg2c"
              primaryColor="green"
              width={750}
              height={400}
            // other props
            />
            <h2>Koulutuspäivät ja liittokokous Lahdessa 10.-11.3.2020</h2>
            <p>
              <a href="/doc/lahti_2020_hakkinen.docx">
                Mikkelin hiippakunnan piispa, Seppo Häkkinen: Näkökulmia hautaan
                siunaamisen käytäntöihin
              </a>
            </p>
            <p>
              <a href="/doc/lahti_2020_nykanen.pptx">
                Eila Nykänen: Lahden seurakuntayhtyhmän hautaustoimen jätehuolto
              </a>
            </p>
            <p>
              <a href="/doc/lahti_2020_seurakuntien_ja_kiviliikkeiden_yhteistyo.pptm">
                Seurakuntien ja kiviliikkeiden yhteistyö - Yhdessä saman asian
                äärellä
              </a>
            </p>
            <p>
              <a href="/doc/lahti_2020_kalliokuusi.pptx">
                Taru-Tiina Kalliokuusi, KIVI Ry
              </a>
            </p>
            <p>
              <a href="/doc/lahti_2020_kontkanen1.pptx">
                Aluekeskusrekisterijohtaja Matti Kontkanen, Päijät-Hämeen
                Keskusrekisteri: Alueelliset keskusrekisterit – tavoite ja
                perustelut
              </a>
              <br />
              <a href="/doc/lahti_2020_kontkanen2.pptx">Aluekeskusrekisterit</a>
            </p>
            <p>
              <a href="/doc/lahti_2020_hautakivipaneeli.pptx">
                Seurakuntien ja kiviliikkeiden yhteistyö - Paneeli
              </a>
            </p>
            <p>
              <a href="/doc/lahti_2020_makinen.pdf">
                Heli Mäkinen, Seurakuntaopisto: Seurakunta- ja
                hautauspalvelualan ammattitutkinto
              </a>
            </p>
            <p>
              <a
                href="https://www.seurakuntaopisto.fi/hakijalle/alkavat-koulutukset-b/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Seurakuntaopiston alkavat koulutukset
              </a>
            </p>
            <h2>Koulutuspäivät ja liittokokous Vantaalla 5.-6.11.2019</h2>
            <p>
              <a href="/doc/vantaa_2019_lehto.pdf">
                Risto Lehto: Hautakummitoiminnan haasteet
              </a>
            </p>
            <p>
              <a href="/doc/vantaa_2019_juslin.pdf">
                Ulf Juslin, Oy J-Trading Ab: Vaihtoehtoiset polttoaineet
              </a>
            </p>
            <p>
              <a href="/doc/vantaa_2019_kietavainen.pdf">
                Katariina Kietäväinen: Krematoriotyön turvallisuusverkosto
              </a>
            </p>
            <p>
              <a href="/doc/vantaa_2019_palo.pdf">
                Harri Palo: Hautaustoimen ajankohtaisia asioita
              </a>
            </p>
            <p>
              <a href="/doc/vantaa_2019_kankare.pdf">
                Ilkka Kankare: Uudet kokonaisratkaisut hautausmaille
              </a>
            </p>
            <h2>Koulutuspäivät ja liittokokous Järvenpäässä 12-13.3.2019</h2>
            <p>
              <a href="/doc/jarvenpaa_hietala.pptx">
                Kari J. Hietala: Avauspuheenvuoro
              </a>
            </p>
            <p>
              <a href="/doc/jarvenpaa_Laine.pdf">
                Sini Laine: Hautakivien jatkokäsittely - kiviteollisuuden
                näkökulma
              </a>
            </p>
            <p>
              <a href="/doc/jarvenpaa_Leskinen.pdf">
                Eija Leskinen: Seurakunnalle palautuvat hautakivet
              </a>
            </p>
            <p>
              <a href="/doc/jarvenpaa_Leskinen2.pdf">
                Eija Leskinen: Hautausmaan hoitosuunnitelma
              </a>
            </p>
            <p>
              <a href="/doc/jarvenpaa_Palo.pdf">
                Harri Palo: Ajankohtaisia asioita hautaustoimessa
              </a>
            </p>
            <h2>Koulutuspäivät ja liittokokous Mikkelissä 15.-16.3.2011</h2>
            <p>
              <a href="/doc/mikkeli_lindstrom.pdf">
                VTM Göran Lindström: Välähdyksiä SHK:n taipaleelta
              </a>
            </p>
            <p>
              <a href="/doc/mikkeli_kirkon_tyo.pdf">
                Hallintojohtaja Markku Salmi: Seurakuntien yhteistyö Mikkelissä
                ja Mikkelin seudulla
              </a>
            </p>
            <p>
              <a href="/doc/mikkeli_hautaustoimilain_mukaiset_tehtavat.pdf">
                Sosiaalineuvos Juha Koponen: Hautaustoimilain mukaiset tehtävät
              </a>
            </p>
            <p>
              <a href="/doc/mikkeli_salow.pdf">
                Ylipuutarhuri Sari Salow: Hautamuistomerkkien uudelleen käyttö
                ja hautojen ”stailaus”
              </a>
            </p>
            <h2>Lahden koulutuspäivät 26.-27.10.2010</h2>
            <p>
              <a href="/doc/lahti_alanne.pdf">
                Puistopäällikkö Sirpa Alanne: Joustavan työajan käyttö
              </a>
            </p>
            <p>
              <a href="/doc/lahti_alanne2.pdf">
                Puistopäällikkö Sirpa Alanne: Työvuorot esimerkki
              </a>
            </p>
            <p>
              <a href="/doc/lahti_aartolahti.pdf">
                Mari Aartolahti: Salpausselän haasteet ja mahdollisuudet
              </a>
            </p>
            <p>
              <a href="/doc/lahti_metso.pdf">
                Kati Metso: Hautausmaiden hoitotason kehittäminen kevyemmäksi
              </a>
            </p>
            <p>
              <a href="/doc/lahti_haikio.pdf">
                Leev Häikiö: Onko krematorio julkinen tila
              </a>
            </p>
            <p>
              <a href="/doc/lahti_vallenius.pdf">
                Leena Vallenius: Rakennemuutos hautaustoimen kannalta
              </a>
            </p>
            <p>
              <a href="/doc/lahti_palo.pdf">Harri Palo: Siikaniemi</a>
            </p>
            <h2>Koulutuspäivät ja liittokokous Turussa 16.-17.3.2010</h2>
            <p>
              <a href="/doc/turku_hanni.pdf">
                Jukka Hanni: Hyvä asiakaspalvelu, miten se toimii ja miten sen
                tulisi toimia
              </a>
            </p>
            <p>
              <a href="/doc/turku_fager.pdf">
                Mia Fager: Hautausoikeus, suostumukset, luvat ja muut
                ongelmatilanteet asiakaspalvelussa
              </a>
            </p>
            <p>
              <a href="/doc/turku_leinonen.pdf">
                Marja-Liisa Leinonen: Kirkon ympäristödiplomi hautausmaiden
                työväline
              </a>
            </p>
            <p>
              <a href="/doc/turku_mannakorpi.pdf">
                Markku Mannakorpi: Tulokaskoulutus
              </a>
            </p>
            <p>
              <a href="/doc/turku_tahvonen.pdf">
                Risto Tahvonen: Julkinen puisto ilmastonmuutoksessa
              </a>
            </p>
            <h2>Imatran koulutuspäivät 20.-21.10.2009</h2>
            <p>
              <a href="/doc/imatra_koistinen.pdf">
                Minna Koistinen: Tuhkan maahan kätkeminen
              </a>
            </p>
            <p>
              <a href="/doc/imatra_lyytinen.pdf">
                Anne Lyytinen: Lääninhallituksen ohjeistusta
              </a>
            </p>
            <p>
              <a href="/doc/imatra_kuronen.pdf">
                Matti J Kuronen: Erilaisuuden kohtaaminen surun ilmaisemisessa
                ja hautaustavoissa
              </a>
            </p>
            <p>
              <a href="/doc/imatra_aho.pdf">
                Minna Aho: Hautausmaiden työturvallisuus
              </a>
            </p>
            <p>
              <a href="/doc/imatra_palo.pdf">
                Harri Palo: Krematorio rekisteriviranomaisena
              </a>
            </p>
            <h2>Joensuun koulutuspäivät 17.-18.3.2009</h2>
            <p>
              <a href="/doc/joensuu_palo.pdf">
                Harri Palo: Krematorio rekisteriviranomaisena
              </a>
            </p>
            <h2>Vaasan koulutuspäivät 21.-22.10.2008</h2>
            <p>
              <a href="/doc/vaasa_palo.pdf">
                Harri Palo: Ajankohtaista tuhkahautauksesta
              </a>
            </p>
            <h2>Varkauden koulutuspäivä 13.11.2007</h2>
            <p>
              <a href="/doc/varkaus_laukkanen.pdf">
                Aarne Laukkanen: Hautaustoimen asema seurakuntaliitoksessa
              </a>
            </p>
            <p>
              <a href="/doc/varkaus_laukkanen2.pdf">
                Aarne Laukkanen: Hautausmaat kaavio
              </a>
            </p>
            <p>
              <a href="/doc/varkaus_lyytinen.pdf">
                Anne-Kaarina Lyytinen: Lääninhallitus hautaustoimen valvojana
              </a>
            </p>
            <p>
              <a href="/doc/varkaus_huokuna.pdf">
                Pekka Huokuna: Hautaustoimi ja seurakuntarakenneuudistus
              </a>
            </p>
            <h2>Kainuun koulutuspäivät 23.-24.10.2007</h2>
            <p>
              <a href="/doc/kainuu_leskinen.pdf">
                Kari Leskinen: Avatun haudan eettiset ohjeet Hautaustoimilain
                näkökulmasta
              </a>
            </p>
            <h2>Vantaan koulutuspäivät 29.-30.3.2007</h2>
            <p>
              <a href="/doc/vantaa_jardi.pdf">
                Pia Jardi: Islamilainen hautaus
              </a>
            </p>
            <h2>Porin koulutuspäivät 24.-25.10.2006</h2>
            <p>
              <a href="/doc/pori_ergonomia.pdf">Ergonomia</a>
            </p>
            <p>
              <a href="/doc/pori_ravinto_terveys.pdf">Työterveys ja ravinto</a>
            </p>
            <p>
              <a href="/doc/pori_tyohyvinvointi.pdf">Työhyvinvointi</a>
            </p>
            <p>
              <a href="/doc/pori_henkinen_jaksaminen.pdf">
                Henkinen jaksaminen kun kohdataan surua
              </a>
            </p>
            <p>
              <a href="/doc/pori_fyysinen_kunto.pdf">
                Fyysisen kunnon ylläpito
              </a>
            </p>
            <h2>Hämeenlinnan koulutuspäivät 4.-5.4.2006</h2>
            <p>
              <a href="/doc/hameenlinna_aaltonen.pdf">
                Seppo Aaltonen: Hautatoimi mediassa
              </a>
            </p>
            <p>
              <a href="/doc/hameenlinna_klemola.pdf">
                Jyri Klemola: Hautausasiakaspalveluun liittyviä oikeudellisia
                kysymyksiä
              </a>
            </p>{" "}
            <p>
              <a href="/doc/hameenlinna_pekuri.pdf">
                Antti Pekuri: Seurakunnalle palautuneet haudat
              </a>
            </p>
            <p>
              <a href="/doc/hameenlinna_heikkinen.pdf">
                Raimo Heikkinen: Ainaishoitojen lakkauttaminen tai
                määräaikaistaminen
              </a>
            </p>
          </div>
          <div className="spacer-75" />
        </div>
      </div>
    </div>
  </Layout >
)

export default AiemmatLuentomateriaalit
